<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs
                            v-model="modelTab"
                            vertical
                            justified
                            nav-class="nav-tabs-custom"
                            content-class="p-3 text-muted scroll--content__landingpage"
                        >
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasicLP v-if="tab.id == 1" :dataForm="formData" :configForm="configFormBasicLP" />
                                    <SettingFirstViewAndTimeApplication
                                        v-else-if="tab.id == 2"
                                        :dataForm="formData"
                                        :configForm="configFormFirstView"
                                    />
                                    <SettingPrize v-else-if="tab.id == 3" :dataForm="formDataEventPrizes" :masterTypes="masterTypeTitlePrize" />
                                    <SettingTargetProduct
                                        v-else-if="tab.id == 4"
                                        :dataForm="formDataTargetProduct"
                                        :configForm="configFormTargetProduct"
                                    />
                                    <SettingDesign v-else-if="tab.id == 7" :dataForm="formDataFirstDesign" />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleSubmit" class="btn btn-primary">{{ $t('btn.save') }}</button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasicLP from '../components/setting-basic/main.vue';
import SettingFirstViewAndTimeApplication from '../components/setting-first_view/main.vue';
import SettingPrize from '../components/setting-prize/main.vue';
import SettingTargetProduct from '../components/setting-target_product/main.vue';
import SettingDesign from '../components/setting-design/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { initFormEventPrizes, initFormTargetProduct, initFormDesign } from './index';
import { TABS, initFormRegister } from '../constants';
import { validateAllField } from '@/utils/validate';
import { configFormBasicLP } from '../components/setting-basic/constants';
import { configFormFirstView } from '../components/setting-first_view/constants';
import { configFormTargetProduct } from '../components/setting-target_product/constants';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { handleDataBeforeSaveLP, validateEndDate, handleDataResponse, handleAlertValidateTabs } from '../handlers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'EditLandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        SettingBasicLP,
        SettingFirstViewAndTimeApplication,
        SettingPrize,
        SettingTargetProduct,
        SettingDesign,
        LoadingIcon
    },
    data() {
        return {
            TABS: TABS,
            formData: initFormRegister(),
            formDataEventPrizes: initFormEventPrizes(),
            formDataTargetProduct: initFormTargetProduct(),
            formDataFirstDesign: initFormDesign(),
            configFormBasicLP: configFormBasicLP(),
            configFormFirstView: configFormFirstView(),
            configFormTargetProduct: configFormTargetProduct(),
            modelTab: 0,
            settingBasicTab: 0,
            masterTypeTitlePrize: [],
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        handleCancel() {
            this.$router.push({
                path: '/landing-page/list'
            });
        },

        async handleSubmit() {
            // let checkTargetProduct = validateAllField(this.configFormTargetProduct);
            let checkValidateTabs = handleAlertValidateTabs(
                {
                    checkBasicLP: validateAllField(this.configFormBasicLP),
                    checkEndDateCP: validateEndDate(
                        this.configFormBasicLP['configFieldCampaignStartDateTime'],
                        this.configFormBasicLP['configFieldCampaignEndDateTime']
                    ),
                    checkEndDateLP: validateEndDate(
                        this.configFormBasicLP['configFieldLPStartDateTime'],
                        this.configFormBasicLP['configFieldLPEndDateTime']
                    ),
                    checkFirstView: validateAllField(this.configFormFirstView)
                },
                this.$bvToast,
                this.modelTab
            );
            if (!checkValidateTabs) return;
            let payload = handleDataBeforeSaveLP(this.formData);
            this.isLoading = true;
            try {
                const data = await this.updateLandingPage(payload);
                if (data.code !== 200) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                    return;
                }
                showMessage(data.code, this.$bvToast, 'Update Success');
                this.getDetail();
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
            console.log('payload', payload);
        },

        async getListMaterCommon() {
            try {
                const dataRes = await this.listMaster('campaign/common-campaign-data');
                this.masterTypeTitlePrize = dataRes['event_prize.type'];
            } catch (error) {
                console.log('error', error);
            }
        },

        async getDetail() {
            if (!this.$route?.query?.id) return;
            this.isLoading = true;
            try {
                const response = await this.getDetailLandingPage(this.$route.query.id);
                handleDataResponse(this.formData, response);
                console.log('response', response);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getDetail();
        this.getListMaterCommon();
    }
};
</script>

<style lang="scss">
.scroll--content__landingpage {
    height: 74vh;
    overflow-y: auto;
}
</style>
